import ElMessage from 'element/ElMessage'
import { Api } from 'lib/api/instance'
import parseSlug from 'lib/helpers/parseSlug'
import pickBy from 'lodash/pickBy'
import Vue from 'vue'

import { i18n } from '@/i18n'
import Creative from '@/models/Creative'

const getPixels = ({
  pixel_inspections: pixelInspections,
  pixel_impressions: pixelImpressions,
  pixel_clicks: pixelClicks,
}) => {
  const getClear = pixels => {
    return pixels.filter(item => item.value).map(item => item.value)
  }
  return {
    impressions: getClear(pixelImpressions),
    clicks: getClear(pixelClicks),
    inspections: getClear(pixelInspections),
  }
}

const getAttachments = attachments => {
  const { video, image, unit, zip } = attachments

  return {
    video: video?.key,
    image: image?.key,
    unit: unit?.key,
    zip: zip?.key,
  }
}

const formatPixels = (pixels) => {
  if (Array.isArray(pixels)) {
    const formattedPixels = []

    pixels.forEach(pixel => {
      if (typeof pixel === 'object' && pixel?.value) {
        formattedPixels.push(pixel.value)
      }
      if (typeof pixel === 'string') {
        formattedPixels.push(pixel)
      }
    })

    return formattedPixels
  }

  return []
}

const getData = creative => {
  const pixels = getPixels(creative.model)

  const data = pickBy({
    ...creative.model,
    slug: creative.slug || creative.ad_set.slug,
    ...getAttachments(creative.model.attachments),
    attachments: undefined,
    pixel_clicks: formatPixels(pixels.clicks),
    pixel_impressions: formatPixels(pixels.impressions),
    pixel_inspections: formatPixels(pixels.inspections),
    // visible: creative.model.visible,
  })

  delete data.legal_compliance

  return data
}

export default {
  namespaced: true,
  state: {
    creative: new Creative(),
    pending: false,
    loading: true,
    errors: {},
    attachmentErrors: [],
  },
  mutations: {
    setCreative (state, payload) {
      state.creative = new Creative(Creative.parseRaw(payload))
      state.creative.setBootstrapped()
    },
    resetCreative (state) {
      state.creative = new Creative()
    },
    setErrors (state, payload) {
      payload.forEach(item => {
        if (!item.field) return
        Vue.set(state.errors, item.field, item.text)
      })
    },
    resetErrors (state) {
      Object.keys(state.errors).forEach(key => {
        Vue.delete(state.errors, key)
      })
    },
    deleteAttachment (state, type) {
      Vue.delete(state.creative.attachments, type)
    },
    addAttachment (state, payload) {
      Vue.set(state.creative.attachments, payload.type, payload.file)
    },
    errorAttachment (state, payload) {
      state.attachmentErrors = payload
    },
    togglePending (state, payload) {
      state.pending = payload
    },
    toggleLoading (state, payload) {
      state.loading = payload
    },
  },
  actions: {
    async fetch ({ commit, state }, slug) {
      const data = { slug: slug || state.creative.slug }

      try {
        commit('toggleLoading', true)
        const res = await Api.get('partner/campaigns/ad/info', data)
        commit('setCreative', res.data)
        return res.data
      }
      finally {
        commit('toggleLoading', false)
      }
    },

    async create ({ commit, state }) {
      const { type } = parseSlug(state.creative.ad_set.slug)
      const data = getData(state.creative)

      try {
        commit('togglePending', true)
        const res = await Api.post(`partner/campaigns/${type}/ad/create`, data)
        return res
      }
      finally {
        commit('togglePending', false)
      }
    },

    async update ({ commit, state, dispatch }, locale) {
      commit('resetErrors')
      const { type } = parseSlug(state.creative.slug)
      const data = getData(state.creative)
      const { product_url } = data

      delete data.attachments
      const checkUrls = await dispatch('checkUrls', product_url)

      if (!checkUrls) {
        Vue.set(state.errors, 'product_url', i18n.tc('validator.urlIsInvalid'))
        return false
      }

      try {
        commit('togglePending', true)
        const res = await Api.post(`partner/campaigns/${type}/ad/update`, data)
        if (res.status) {
          ElMessage.success(locale.form.formSendStatus.updated)
        }
        else {
          commit('setErrors', res.origin.messages)
        }
        return res
      }
      finally {
        commit('togglePending', false)
      }
    },
    checkUrls (context, value) {
      const isValidUrl = (url) => {
        const urlPattern = new RegExp('^(https?:\\/\\/)?' + // Протокол
              '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.?)+[a-zA-Z]{2,}|' + // Доменное имя
              '((\\d{1,3}\\.){3}\\d{1,3}))' + // или IP (v4) адрес
              '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // Порт и путь
              '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // Параметры запроса
              '(\\#[-a-zA-Z\\d_]*)?$', 'i') // Якорь

        // Если URL не соответствует шаблону, возвращаем false
        if (!urlPattern.test(url)) {
          return false
        }

        // Проверка на использование https
        return url.startsWith('https://')
      }

      // Функция для проверки, является ли строка URL
      const isUrl = (value) => {
        try {
          const url = new URL(value)
          return true
        }
        catch (err) {
          return false
        }
      }

      try {
        // Проверка корректности URL
        const validUrl = isValidUrl(value)
        const isUrlResult = isUrl(value)

        // Если обе проверки прошли успешно, возвращаем true
        return validUrl && isUrlResult
      }
      catch (err) {
        return false
      }
    },

    checkAttachments ({ state }, locale) {
      if (state.creative._origin.ad_set?.format === 'chatbot_text') {
        return true
      }

      if (state.attachmentErrors.length) {
        ElMessage.error(locale.form.files.errors.general)
        return false
      }

      let hasAttachment = false
      switch (state.creative.ad_set.format) {
        case 'video':
          hasAttachment = !!state.creative.attachments.video
          break
        case 'pip_video':
          hasAttachment = !!state.creative.attachments.video
          break
        case 'custom':
          hasAttachment = !!state.creative.attachments.zip
          break
      }

      if (!hasAttachment) {
        ElMessage.error(locale.form.files.errors.general)
      }
      return hasAttachment
    },

    async deleteAttachment ({ state, commit }, attachmentType, locale) {
      const data = {
        slug: state.creative.slug || state.creative.ad_set.slug,
        field: attachmentType,
      }
      const creativeType = state.creative?.ad_set?.campaign?.type

      try {
        const res = await Api.post(`partner/campaigns/${creativeType}/ad/attach/delete`, data)
        if (res.status) {
          ElMessage.success(locale.deleted)
        }
        else {
          commit('setErrors', res.origin.messages)
        }
        return res
      }
      finally {
        commit('deleteAttachment', attachmentType)
      }
    },

    addAttachment ({ commit }, attachment) {
      commit('addAttachment', attachment)
    },

    errorAttachment ({ commit }, errors) {
      commit('errorAttachment', errors)
    },

    reset ({ commit }) {
      commit('resetCreative')
      commit('resetErrors')
      commit('toggleLoading', true)
    },
  },

  getters: {
    creative: ({ creative }) => {
      if (creative.chatbot_text) {
        creative.chatbot_text = creative.chatbot_text.replace(/\n/g, ' ')
      }

      return creative
    },
    attachments: ({ creative }) => creative.attachments,
    format: ({ creative }) => creative._origin.ad_set?.format,
    pending: ({ pending }) => pending,
    loading: ({ loading }) => loading,
    errors: ({ errors }) => errors,
  },
}
