<template>
  <DashboardSection :title="locale.title">
    <ProductUrl :model="model" />
    <ChatbotText :model="model" />
    <!-- <CompanionHeading :model="model" /> -->
    <!-- <CompanionText :model="model" /> -->
    <!-- <CompanionCta :model="model" /> -->
    <QrCode :model="model" />
    <PixelClicks :model="model" />
    <!-- <PixelImpressions :model="model" /> -->
    <!-- <PixelInspections :model="model" /> -->
  </DashboardSection>
</template>

<script>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import ChatbotText from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/ChatbotText'
// import CompanionCta from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/CompanionCta'
// import CompanionHeading from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/CompanionHeading'
// import CompanionText from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/CompanionText'
import PixelClicks from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/PixelClicks'
// import PixelImpressions from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/PixelImpressions'
// import PixelInspections from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/PixelInspections'
import ProductUrl from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/ProductUrl'
import QrCode from 'Partner/pages/PartnerCreative/components/CreativeSettings/fields/QrCode'

export default {
  components: {
    DashboardSection,
    ProductUrl,
    ChatbotText,
    // CompanionHeading,
    // CompanionText,
    // CompanionCta,
    QrCode,
    PixelClicks,
    // PixelImpressions,
    // PixelInspections,
  },

  props: {
    model: VueTypes.object.isRequired,
  },

  computed: {
    locale: ({ $locale }) => ({
      title: $locale('form.data.title'),
    }),
  },
}
</script>
